export default [
  {
    title: 'STT',
    dataIndex: 'rowIndex',
    scopedSlots: { customRender: 'rowIndex' },
    align: 'center',
    width: 50
  },
  {
    title: 'Service Code',
    dataIndex: 'shopeePoId',
    scopedSlots: { customRender: 'shopeePoId' },
    align: 'center',
    ellipsis: true,
    width: 100
  },
  {
    title: 'MAWB',
    dataIndex: 'descOfGoods',
    scopedSlots: { customRender: 'descOfGoods' },
    align: 'center',
    ellipsis: true,
    width: 150
  },
  {
    title: 'Carton',
    dataIndex: 'cartonNo',
    scopedSlots: { customRender: 'cartonNo' },
    align: 'center',
    ellipsis: true,
    width: 120
  },
  {
    title: 'Dest',
    dataIndex: 'dest',
    scopedSlots: { customRender: 'dest  ' },
    align: 'center',
    ellipsis: true,
    width: 100
  },
  {
    title: 'Biển số xe từ HN đến kho thông quan nhận hàng',
    dataIndex: 'hncPlateNumber',
    scopedSlots: { customRender: 'hncPlateNumber' },
    align: 'center',
    ellipsis: true,
    width: 150
  },
  {
    title: 'Thời gian bắt đầu nhận hàng ở kho thông quan',
    dataIndex: 'hncImportAt',
    scopedSlots: { customRender: 'hncImportAt' },
    align: 'center',
    ellipsis: true,
    width: 180
  },
  {
    title: 'Thời gian sau khi nhận hàng xong ở kho thông quan, bắt đầu xuất phát',
    dataIndex: 'hncExportAt',
    scopedSlots: { customRender: 'hncExportAt' },
    align: 'center',
    ellipsis: true,
    width: 180
  },
  {
    title: 'Biển số xe giao đến SPX',
    dataIndex: 'hcmcPlateNumber',
    scopedSlots: { customRender: 'hcmcPlateNumber' },
    align: 'center',
    ellipsis: true,
    width: 150
  },
  {
    title: 'Thời gian xuất phát đến SPX',
    dataIndex: 'hcmcExportAt',
    scopedSlots: { customRender: 'hcmcExportAt' },
    align: 'center',
    ellipsis: true,
    width: 180
  },
  {
    title: 'Thời gian đến kho SPX',
    dataIndex: 'spxArrivalTime',
    scopedSlots: { customRender: 'spxArrivalTime' },
    align: 'center',
    ellipsis: true,
    width: 180
  },
  {
    title: 'Thời gian SPX bắt đầu nhận hàng',
    dataIndex: 'spxStartReceiveTime',
    scopedSlots: { customRender: 'spxStartReceiveTime' },
    align: 'center',
    ellipsis: true,
    width: 180
  },
  {
    title: 'Thời gian SPX ký nhận',
    dataIndex: 'deliveredAt',
    scopedSlots: { customRender: 'deliveredAt' },
    align: 'center',
    ellipsis: true,
    width: 180
  }
]
// shopeePoId
// descOfGoods
// CartonNo
// Dest
// hncPlateNumber
// hncImportAt
// hncExportAt
// hcmcPlateNumber
// hcmcExportAt
// spxArrivalTime
// spxStartReceiveTime
// deliveredAt
